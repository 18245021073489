module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aseem Shrey","short_name":"Aseem Shrey","description":"Security Engineer, Teacher and Creator. This is my online abode.","start_url":"/","background_color":"white","theme_color":"#5183f5","display":"minimal-ui","icon":"static/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bc36843183517c2c8f2c14ee575eb982"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":false,"showCaptions":["title"],"markdownCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images-zoom/gatsby-browser.js'),
      options: {"plugins":[],"margin":1,"background":"#fff"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
